  <template>
  <v-row class="mt-n4">
    <v-col>
      <v-row v-if="promise.invoices.length == 0 && promiseType !== 'onInvoice'">
        <v-col cols="3">
          <currencies
            :currency.sync="currency"
            :disabled="!canUpdateAmount"
            :accountId.sync="promise.accountId"
          ></currencies>
        </v-col>
        <v-col>
          <amount-field
            :currency="currency"
            :rules="amountRules"
            :amount.sync="amount"
            :disabled="!canUpdateAmount"
            :label="$t('t.AmountPromised')"
            reverse
            class="ml-2"
          />
        </v-col>
      </v-row>
      <div v-if="promiseType !== 'onAccount'">
        <v-row v-show="promise.invoices.length > 0">
          <v-col>
            <invoice-form-table
              :headers="headers"
              :items.sync="invoices"
              :disabled="!canUpdateAmount"
            />
          </v-col>
        </v-row>
        <v-row v-if="canUpdateAmount && !invoiceReadOnly">
          <v-col>
            <v-expansion-panels
              accordion
              focusable
              :value="promiseType === 'onInvoice' && !promise.invoices.length ? 0 : undefined"
            >
              <v-expansion-panel v-show="canUpdateAmount">
                <v-expansion-panel-header class="text-h6">{{$t('t.SelectInvoicePromised')}}</v-expansion-panel-header>
                <v-expansion-panel-content class="background-plain">
                  <promise-invoices
                    :invoices.sync="invoices"
                    :promise="promise"
                    @row-action="computeAmount"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import Decimal from 'decimal.js'

export default {
  components: {
    PromiseInvoices: () => import('./promise-invoices'),
    AmountField: () => import('@/components/amount-field'),
    InvoiceFormTable: () => import('@/components/invoice-form-table'),
    Currencies: () => import('@/components/currencies')
  },
  data () {
    return {
      headers: [
        { text: this.$t('t.Number#'), value: 'number' },
        { text: this.$t('t.Date'), value: 'date' },
        { text: this.$t('t.AmountInpromise'), value: 'amountPromised', align: 'right' },
        { text: this.$t('t.Amount'), value: 'amount', align: 'right' },
        { text: this.$t('t.Balance'), value: 'balance', align: 'right' },
        { text: '', value: 'openInvoice', sortable: false },
        { text: '', value: 'remove', sortable: false }
      ],
      amountRules: [
        v => !this.lodash.isNil(v) || this.$t('t.IsRequired'),
        v => v > 0 || this.$t('t.MustBeGreaterThan', { value: 0 }),
        v => v <= 92233720368547 || this.$t('t.MustBeLesserThan', { value: 92233720368547 })
      ]
    }
  },
  computed: {
    amount: {
      get: function () {
        return this.promise.amount
      },
      set: function (amount) {
        this.$emit('update:promise', Object.assign({}, this.promise, { amount }))
      }
    },
    currency: {
      get: function () {
        return this.promise.currency
      },
      set: function (currency) {
        this.$emit('update:promise', Object.assign({}, this.promise, { currency }))
      }
    },
    canUpdateAmount () {
      return (this.promise?.status?.id ?? 0) === 0 && !this.promise.installments.some(i => i.paidDate)
    },
    invoices: {
      get: function () {
        return this.promise.invoices
      },
      set: function (invoices) {
        this.$nextTick(() => { this.computeAmount() })
        this.$emit('update:promise', Object.assign({}, this.promise, { invoices, currency: invoices[0]?.currency || this.$store.getters.currentUser.currency }))
      }
    },
    invoiceReadOnly () {
      return !this.$store.getters.currentUserHasPermission('Invoices')
    }
  },
  methods: {
    computeAmount () {
      this.$nextTick(() => { this.amount = this.invoices.reduce((acc, i) => acc.plus(new Decimal(i.amountPromised)), new Decimal(0)).toNumber() })
    }
  },
  props: {
    promise: Object,
    promiseType: String
  }
}
</script>

<style lang="stylus" scoped>
.document-list
  height 400px
</style>
